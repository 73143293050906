<template>
  <v-dialog v-model="data.dialog" persistent max-width="600px">
    <v-card v-if="loading" :color="isDelete ? 'error' : 'primary'" dark>
      <v-card-text>
        <span class="font-weight-bold text-white">{{ $t('loading') }}</span>
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-if="!loading">
      <v-card-title
        :class="{'form-danger': isDelete, 'form-warning': isPassword || isDevice, 'form-info': isUpdate, 'form-primary': isCreate}"
      >
        <span v-if="isCreate" class="">{{ $('create') }}</span>
        <span v-if="isUpdate" class="">{{ $('update') }}</span>
        <span v-if="isDelete" class="">{{ $('delete') }}</span>
        <span v-if="isPassword && item" class="">كلمة المرور الطالب - {{ item.name }}</span>
        <span v-if="isDevice" class="">تبدل الجهاز</span>
      </v-card-title>
      <v-card-text>
        <h2 v-if="isDevice && item" class="my-3 text-center">
          هل انت متأكد من تبديل جهاز الطالب {{ item.name }}
        </h2>
        <h2 v-if="isPassword && item" class="my-3 text-center">
          {{ item.password }}
        </h2>
        <h2 v-if="isDelete && item" class="my-3">
          {{ $('delete_msg', [item.name]) }}
        </h2>
        <v-form
          v-if="isCreate || isUpdate"
          ref="form"
          v-model="valid"
        >
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <v-text-field
                  v-model="form.name"
                  v-max-length="200"
                  :label="$('name')"
                  :rules="rules.name"
                  autocomplete="new-password"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-text-field
                  v-model="form.password"
                  v-max-length="200"
                  :label="$('password')"
                  autocomplete="new-password"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-select
                  v-model="form.group_id"
                  :items="filteredGroups"
                  :label="$('group_id')"
                  item-value="id"
                  item-text="name"
                  clearable
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="light"
          outlined
          @click="dialogModal(false)"
        >
          {{ $t('form.cancel') }}
        </v-btn>
        <v-btn
          v-if="isCreate"
          color="primary"
          :disabled="!valid || submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          {{ $t('form.create') }}
        </v-btn>
        <v-btn
          v-if="isUpdate"
          color="info"
          :disabled="!valid || submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          {{ $t('form.save') }}
        </v-btn>
        <v-btn
          v-if="isDevice"
          color="warning"
          :disabled="submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          تبديل
        </v-btn>
        <v-btn
          v-if="isDelete"
          color="error"
          :disabled="submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          {{ $t('form.delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from '@axios'
import toast from '@/store/toast'

export default {
  name: 'StudentDialog',
  props: {
    data: {
      type: Object,
      default: () => ({
        dialog: false,
        id: null,
        type: 'create',
      }),
    },
    dialogModal: {
      type: Function,
      default: state => {
        this.data.dialog = state
      },
    },
    translationKey: {
      type: String,
      default: () => '',
    },
  },
  emits: ['update:table'],
  data: () => ({
    loading: true,
    submitLoading: false,
    valid: false,
    item: null,
    groups: [],
    form: {
      name: '',
      password: '',
      group_id: null,
    },
  }),
  computed: {
    isCreate() {
      return this.data.type === 'create'
    },
    isUpdate() {
      return this.data.type === 'update'
    },
    isDelete() {
      return this.data.type === 'delete'
    },
    isPassword() {
      return this.data.type === 'password'
    },
    isDevice() {
      return this.data.type === 'device_reset'
    },
    filteredGroups() {
      if (!this.item) return []

      return [
        { id: null, name: 'بدون مجموعة' },
        ...this.groups.filter(g => g.stage_id === this.item.stage_id),
      ]
    },
    rules() {
      const rules = {};
      rules.name = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.name`)]),
      ];

      return rules;
    },
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(val) {
        try {
          this.loading = true;
          if (val.id) {
            const student = await axios.get(`/students/${val.id}`);
            this.item = student.data.data;
            this.form.name = this.item.name;
            this.form.password = this.item.password;
            this.form.group_id = this.item.group_id ?? null;
          }
          if (!val.dialog) {
            this.resetForm();
          }
        } catch (error) {
          //   await toast.dispatch('error', error.response ? error.response.data.message : error.message)
        } finally {
          this.loading = false;
        }
      },
    },
  },
  async mounted() {
    const response = await axios.get('/groups')
    this.groups = response.data.data
  },
  methods: {
    async submit() {
      try {
        this.submitLoading = true;
        if (this.isDevice) {
          await axios.post(`/students/device/${this.data.id}`);
          await toast.dispatch('success', 'تم تبديل الجهاز بنجاح')
          this.$emit('update:table')
          this.dialogModal(false)

          return;
        }
        if (this.isDelete) {
          await axios.delete(`/students/delete/${this.data.id}`);
          await toast.dispatch('success', this.$t('form.success.delete'))
          this.$emit('update:table')
          this.dialogModal(false)

          return;
        }
        if (this.$refs.form.validate() === false) {
          return;
        }
        if (this.isCreate) {
          await axios.post('/students/create', this.form);
          await toast.dispatch('success', this.$t('form.success.create'))
        } else if (this.isUpdate) {
          await axios.post(`/students/edit/${this.data.id}`, this.form);
          await toast.dispatch('success', this.$t('form.success.update'))
        }
        this.$emit('update:table')
        this.dialogModal(false)
      } catch (error) {
        //   await toast.dispatch('error', error.response ? error.response.data.message : error.message)
      } finally {
        this.submitLoading = false;
      }
    },
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.form = {
        name: '',
        password: '',
        group_id: null,
      };
      this.item = null;
    },
    $(key, parameters = null) {
      return this.$t(`${this.translationKey}.modal.${key}`, parameters);
    },
  },
}
</script>

<style scoped>

</style>
