<template>
  <v-card>
    <v-card-title>
      <!--      <v-btn-->
      <!--        id="btn1"-->
      <!--        v-can="'student.create'"-->
      <!--        color="primary"-->
      <!--        dark-->
      <!--        @click="dialogModal(true, 'create')"-->
      <!--      >-->
      <!--        <v-icon dark>-->
      <!--          mdi-plus-->
      <!--        </v-icon>-->
      <!--        {{ $('create') }}-->
      <!--      </v-btn>-->
      <v-spacer></v-spacer>
    </v-card-title>
    <v-data-table
      class="dd"
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="total"
      :loading="loading"
      :disable-filtering="true"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-page-first',
        lastIcon: 'mdi-page-last',
        itemsPerPageOptions:[10, 20, 50, -1],
        itemsPerPageText: $t('dataTable.itemsPerPageText'),
        pageText: $t('dataTable.pageText'),
        itemsPerPageAllText: $t('dataTable.itemsPerPageAllText'),
      }"
      :hide-default-header="!$vuetify.breakpoint.mobile"
      @update:page="fetch"
      @update:items-per-page="fetch"
    >
      <template v-if="!$vuetify.breakpoint.mobile" v-slot:header="{ props }">
        <datatable-header :props="props" :options.sync="options" :translation-key="translationKey" @update:filters="fetch"></datatable-header>
      </template>
      <template v-slot:header.actions="{ header }">
        {{ header.text }}
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip v-if="item.status === 'pending'" color="info" small>
          {{ $t("pages.student.statuses." + item.status) }}
        </v-chip>
        <v-chip v-if="item.status === 'active'" color="primary" small>
          {{ $t("pages.student.statuses." + item.status) }}
        </v-chip>
        <v-chip v-if="item.status === 'suspended'" color="error" small>
          {{ $t("pages.student.statuses." + item.status) }}
        </v-chip>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | format_date }}
      </template>
      <template v-slot:item.group.id="{ item }">
        <v-chip v-if="item.group" color="info" label small>
          {{ item.group.name }}
        </v-chip>
        <span v-else>بدون مجموعة</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <div id="actions" class="text-center">
          <v-progress-circular
            v-if="loadings.indexOf(item.id) !== -1"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <v-menu v-if="loadings.indexOf(item.id) == -1" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                color="dark"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <menu-item v-if="item.status === 'pending'" label="قبول الطالب" color="success" icon="mdi-check" @click="updateStatus(item, 'active')"></menu-item>
              <menu-item v-if="item.status === 'active'" label="تعطيل الطالب" color="error" icon="mdi-close-outline" @click="updateStatus(item, 'suspended')"></menu-item>
              <menu-item v-if="item.status === 'suspended'" label="تفعيل الطالب" color="success" icon="mdi-check" @click="updateStatus(item, 'active')"></menu-item>
              <menu-item :label="$('attendance')" color="warning" icon="mdi-account-school" @click="dialogModalAttendances(true, 'attendances', item.id)"></menu-item>
              <menu-item label="عرض كلمة المرور" color="primary" icon="mdi-key" @click="dialogModal(true, 'password', item.id)"></menu-item>
              <menu-item label="تبديل الجهاز" color="warning" icon="mdi-cellphone-key" @click="dialogModal(true, 'device_reset', item.id)"></menu-item>
              <menu-item :label="$('update')" color="info" icon="mdi-pencil" @click="dialogModal(true, 'update', item.id)"></menu-item>
              <menu-item :label="$('delete')" color="error" icon="mdi-delete" @click="dialogModal(true, 'delete', item.id)"></menu-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
    <action-dialog
      :dialog-modal="dialogModal"
      :data="dialogData"
      :translation-key="translationKey"
      @update:table="fetch"
    ></action-dialog>
    <attendances-dialog
      :dialog-modal="dialogModal"
      :data="dialogDataAttendances"
      :translation-key="translationKey"
      @update:table="fetch"
    ></attendances-dialog>
  </v-card>
</template>

<script>
import axios from '@axios'
import ActionDialog from './dialog.vue'
import DatatableHeader from '@/components/dataTables/DatatableHeader.vue'

import { ObjectToQuery } from '@/plugins/helper';
import { getFilters, getOptions } from '@/components/dataTables/helper'
import MenuItem from '@/components/menu/menuItem.vue';
import AttendancesDialog from '@/views/pages/student/AttendancesDialog.vue'

export default {
  name: 'Students',
  components: {
    DatatableHeader, ActionDialog, MenuItem, AttendancesDialog,
  },
  data: () => ({
    translationKey: 'pages.student',
    filters: false,
    items: [],
    total: 0,
    loading: true,
    options: {},
    tempOptions: '',
    search: '',
    loadings: [],
    dialogData: {
      dialog: false,
      type: 'create',
      id: null,
    },
    dialogDataAttendances: {
      dialog: false,
      type: 'create',
      id: null,
    },
    headers: [
      {
        text: 'name',
        align: 'center',
        sortable: false,
        value: 'name',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'phone',
        align: 'center',
        sortable: false,
        value: 'phone',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },

      // {
      //   text: 'email',
      //   align: 'center',
      //   sortable: false,
      //   value: 'email',
      //   sort: true,
      //   filter: {
      //     menu: false,
      //     type: 'text',
      //     methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
      //   },
      // },

      {
        text: 'stage',
        align: 'center',
        sortable: false,
        value: 'stage.name',
        sort: true,
        filter: {
          menu: false,
          type: 'select',
          api: async $axios => {
            const items = await $axios.get('/stages')

            return items.data.data.map(item => item.name)
          },
          options: [],
        },
      },
      {
        text: 'group',
        align: 'center',
        sortable: false,
        value: 'group.id',
        sort: true,
        filter: {
          menu: false,
          type: 'select',
          selectText: 'full_name',
          selectValue: 'id',
          api: async $axios => {
            const items = await $axios.get('/groups')

            return items.data.data
          },
          options: [],
        },
      },
      {
        text: 'status',
        align: 'center',
        sortable: false,
        value: 'status',
        sort: true,
        filter: {
          menu: false,
          type: 'select',
          options: ['pending', 'active', 'suspended'],
          translatable: true,
          translation_parent: 'pages.student.statuses.',
        },
      },
      {
        text: 'created_at',
        align: 'center',
        sortable: false,
        value: 'created_at',
        sort: true,
        filter: {
          menu: false,
          type: 'date',
          methods: ['greater', 'less', 'between'],
        },
      },
      {
        text: 'device_name',
        align: 'center',
        sortable: false,
        value: 'device_name',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'actions',
        value: 'actions',
        align: 'center',
      },
    ],
  }),
  mounted() {
    this.options = getOptions(this.$route, this.options);
    this.fetch();
  },

  methods: {
    async fetch() {
      if (JSON.stringify(this.options) !== this.tempOptions) {
        this.tempOptions = JSON.stringify(this.options);
        await this.$router.replace({ query: this.options })
      }
      window.scrollTo(0, 0);
      localStorage.setItem(`itemsPerPage_${this.$route.name}`, this.options.itemsPerPage);
      this.loading = true;
      const filters = getFilters(this.headers);
      const query = ObjectToQuery({
        ...this.options,
        filters,
      })
      const response = await axios.get(`/students/dt?${query}`);
      this.items = response.data.data.data
      this.total = response.data.data.total
      this.loading = false
    },
    downloadPDF() {
      window.print()
    },
    dialogModal(dialog, type = this.dialogData.type, id = null) {
      this.dialogData.dialog = dialog;
      this.dialogData.type = type;
      this.dialogData.id = id;
    },
    dialogModalAttendances(dialog, type = this.dialogData.type, id = null) {
      this.dialogDataAttendances.dialog = dialog;
      this.dialogDataAttendances.type = type;
      this.dialogDataAttendances.id = id;
    },
    async updateStatus(item, status) {
      this.loadings.push(item.id);
      await axios.post(`/students/edit/${item.id}`, {
        status,
      });
      this.loadings.splice(this.loadings.indexOf(item.id), 1);
      item.status = status;
    },
    $(key, parameters = null) {
      return this.$t(`${this.translationKey}.${key}`, parameters);
    },
  },

}
</script>
